import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LouvorMinisterio from './LouvorMinisterio';
import AdminLogin from './AdminLogin';
import AdminPanel from './AdminPanel';
import './index.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LouvorMinisterio />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/playlist/:playlistId" element={<LouvorMinisterio />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
