import React, { useState, useEffect, useRef } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  setDoc,
  doc,
  getDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { FaPlay, FaPause, FaForward, FaBackward, FaPlus, FaShare } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button, Form, ListGroup, Dropdown } from "react-bootstrap";

const firebaseConfig = {
  apiKey: "AIzaSyDb93paSqsSU-9NKUeF8DR2qoA_xuVrRMY",
  authDomain: "cinepib-ca74f.firebaseapp.com",
  projectId: "cinepib-ca74f",
  storageBucket: "cinepib-ca74f.appspot.com",
  messagingSenderId: "882257150511",
  appId: "1:882257150511:web:f3e22139d11add178d7cda",
  measurementId: "G-SX0L31ZBZT",
};
// Inicializa o Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const LouvorMinisterio = () => {
  const [versiculos, setVersiculos] = useState([]);
  const [filteredVersiculos, setFilteredVersiculos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showWeeklyVerse, setShowWeeklyVerse] = useState(false);
  const [playingAudio, setPlayingAudio] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedVersiculo, setSelectedVersiculo] = useState(null);
  const [playlists, setPlaylists] = useState([]);
  const [playlistEndDate, setPlaylistEndDate] = useState("");
  const [selectedPlaylist, setSelectedPlaylist] = useState("");
  const [newPlaylistName, setNewPlaylistName] = useState("");
  const [selectedPlaylistId, setSelectedPlaylistId] = useState("");
  const audioRef = useRef(new Audio());
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const { playlistId } = useParams(); // Para pegar o ID da playlist da URL

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/admin-login");
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchVersiculos();
      await fetchPlaylists();
      await deleteExpiredPlaylists();

      // Se houver um playlistId na URL, carregue essa playlist
      if (playlistId) {
        setSelectedPlaylistId(playlistId);
        await fetchPlaylistVersiculos(playlistId);
      }
    };

    fetchData();

    // Configurar um intervalo para verificar playlists expiradas a cada hora
    const interval = setInterval(deleteExpiredPlaylists, 3600000);

    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(interval);
  }, []);

  const fetchVersiculos = async () => {
    const vslouvorRef = collection(db, "vslouvor");
    const vslouvorSnapshot = await getDocs(vslouvorRef);
    const versiculosData = vslouvorSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setVersiculos(versiculosData);
    setFilteredVersiculos(versiculosData);
  };

  const fetchPlaylists = async () => {
    const playlistsRef = collection(db, "playlistvs");
    const playlistsSnapshot = await getDocs(playlistsRef);
    const playlistsData = playlistsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setPlaylists(playlistsData);
  };

  const deleteExpiredPlaylists = async () => {
    const today = new Date();
    const playlistsRef = collection(db, "playlistvs");
    const expiredPlaylistsQuery = query(
      playlistsRef,
      where("endDate", "<", today)
    );
    const expiredPlaylistsSnapshot = await getDocs(expiredPlaylistsQuery);

    const deletePromises = expiredPlaylistsSnapshot.docs.map((doc) =>
      deleteDoc(doc.ref)
    );
    await Promise.all(deletePromises);

    // Atualizar o estado local após a exclusão
    await fetchPlaylists();
  };

  useEffect(() => {
    if (selectedPlaylistId) {
      fetchPlaylistVersiculos(selectedPlaylistId);
    } else {
      setFilteredVersiculos(versiculos);
    }
  }, [selectedPlaylistId, versiculos]);

  const fetchPlaylistVersiculos = async (playlistId) => {
    const playlistRef = doc(db, "playlistvs", playlistId);
    const playlistSnap = await getDoc(playlistRef);
    if (playlistSnap.exists()) {
      const playlistData = playlistSnap.data();
      if (Array.isArray(playlistData.versiculos)) {
        setFilteredVersiculos(playlistData.versiculos);
      } else {
        console.log("Versiculos is not an array in playlist data");
        setFilteredVersiculos([]);
      }
    } else {
      console.log("Playlist not found");
      setFilteredVersiculos([]);
    }
  };

  const handleCardClick = (versiculo) => {
    setSelectedVersiculo(versiculo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVersiculo(null);
    setNewPlaylistName("");
    setSelectedPlaylist("");
    setPlaylistEndDate("");
  };

  const handleAddToPlaylist = async () => {
    if (!selectedVersiculo) return;

    const versiculoData = {
      id: selectedVersiculo.id,
      name: selectedVersiculo.name,
      singer: selectedVersiculo.singer,
      audioUrl: selectedVersiculo.audioUrl,
      coverUrl: selectedVersiculo.coverUrl,
      downloadUrl: selectedVersiculo.downloadUrl,
      tones: selectedVersiculo.tones,
    };

    if (selectedPlaylist) {
      // Adicionar a uma playlist existente
      const playlistRef = doc(db, "playlistvs", selectedPlaylist);
      const playlist = playlists.find((p) => p.id === selectedPlaylist);
      await setDoc(
        playlistRef,
        {
          versiculos: [...playlist.versiculos, versiculoData],
        },
        { merge: true }
      );
    } else if (newPlaylistName) {
      // Criar uma nova playlist
      const endDate = new Date(playlistEndDate);
      await addDoc(collection(db, "playlistvs"), {
        name: newPlaylistName,
        versiculos: [versiculoData],
        endDate: endDate,
      });
    }

    // Atualizar o estado local das playlists
    await fetchPlaylists();

    handleCloseModal();
  };

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener("ended", () => setPlayingAudio(null));
    audio.addEventListener("timeupdate", updateTime);
    audio.addEventListener("loadedmetadata", () => setDuration(audio.duration));

    return () => {
      audio.removeEventListener("ended", () => setPlayingAudio(null));
      audio.removeEventListener("timeupdate", updateTime);
      audio.removeEventListener("loadedmetadata", () =>
        setDuration(audio.duration)
      );
    };
  }, []);

  const updateTime = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = versiculos.filter(
      (v) =>
        v.name.toLowerCase().includes(term) ||
        v.singer.toLowerCase().includes(term)
    );
    setFilteredVersiculos(filtered);
  };

  const toggleWeeklyVerse = () => {
    setShowWeeklyVerse(!showWeeklyVerse);
    if (showWeeklyVerse) {
      setFilteredVersiculos(versiculos);
      setSelectedPlaylistId("");
    } else {
      if (selectedPlaylistId) {
        fetchPlaylistVersiculos(selectedPlaylistId);
      } else {
        setFilteredVersiculos(versiculos);
      }
    }
  };

  const toggleAudio = (audioUrl) => {
    if (playingAudio === audioUrl) {
      audioRef.current.pause();
      setPlayingAudio(null);
    } else {
      if (playingAudio) {
        audioRef.current.pause();
      }
      audioRef.current.src = audioUrl;
      audioRef.current.play();
      setPlayingAudio(audioUrl);
    }
  };

  const handleSeek = (e) => {
    const time = parseFloat(e.target.value);
    audioRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const skip = (seconds) => {
    audioRef.current.currentTime += seconds;
  };

  const handleShare = (playlistId) => {
    const url = `${window.location.origin}/playlist/${playlistId}`;
    setShareUrl(url);
    setShareModalOpen(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    // Você pode adicionar um feedback visual aqui, como um toast ou uma mensagem
  };

  const shareOnSocialMedia = (platform) => {
    let shareLink;
    switch (platform) {
      case 'whatsapp':
        shareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(`Confira esta playlist do Ministério de Louvor PIBGTA: ${shareUrl}`)}`;
        break;
      case 'facebook':
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      case 'twitter':
        shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent('Confira esta playlist do Ministério de Louvor PIBGTA')}`;
        break;
      default:
        return;
    }
    window.open(shareLink, '_blank');
  };

  return (
    <div id="louvor-ministerio-container">
      <div id="louvor-ministerio-header" onClick={handleLogoClick}>
        <h1 id="louvor-ministerio-title">Ministério de Louvor PIBGTA</h1>
      </div>
      <div id="search-container">
        <input
          id="search-input"
          type="text"
          placeholder="Pesquisar"
          value={searchTerm}
          onChange={handleSearch}
        />
        <select
          value={selectedPlaylistId}
          onChange={(e) => setSelectedPlaylistId(e.target.value)}
        >
          <option value="">
            {selectedPlaylistId ? "Voltar a tela inicial" : "Playlist da Escala Semanal"}
          </option>
          {playlists.map((playlist) => (
            <option key={playlist.id} value={playlist.id}>
              {playlist.name}
            </option>
          ))}
        </select>
        {selectedPlaylistId && (
       <Button onClick={() => handleShare(selectedPlaylistId)} className="share-button">
       <FaShare /> 
     </Button>
        )}
      </div>
      <div id="versiculos-grid">
        {filteredVersiculos.length > 0 ? (
          filteredVersiculos.map((versiculo) => (
            <div
              key={versiculo.id}
              className="versiculo-card"
              onClick={() => handleCardClick(versiculo)}
            >
              <img
                src={versiculo.coverUrl}
                alt={versiculo.name}
                className="versiculo-cover"
              />
              <div className="versiculo-info">
                <h2 className="versiculo-name">{versiculo.name}</h2>
                <p className="versiculo-singer">{versiculo.singer}</p>
                <p className="versiculo-tones">
                  Tom: {versiculo.tones.join(", ")}
                </p>
                <div
                  className="audio-controls"
                  onClick={(e) => e.stopPropagation()}
                >
                  <button onClick={() => skip(-10)} className="skip-btn">
                    <FaBackward />
                  </button>
                  <button
                    onClick={() => toggleAudio(versiculo.audioUrl)}
                    className="play-pause-btn"
                  >
                    {playingAudio === versiculo.audioUrl ? (
                      <FaPause />
                    ) : (
                      <FaPlay />
                    )}
                  </button>
                  <button onClick={() => skip(10)} className="skip-btn">
                    <FaForward />
                  </button>
                </div>
                {playingAudio === versiculo.audioUrl && (
                  <div
                    className="audio-progress"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type="range"
                      min={0}
                      max={duration}
                      value={currentTime}
                      onChange={handleSeek}
                      className="progress-bar"
                    />
                    <div className="time-display">
                      <span>{formatTime(currentTime)}</span>
                      <span>{formatTime(duration)}</span>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="card-actions download-section"
                onClick={(e) => e.stopPropagation()}
              >
                <a
                  href={versiculo.downloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-btn"
                >
                  Download
                </a>
              </div>
            </div>
          ))
        ) : null}
      </div>
      <Modal show={shareModalOpen} onHide={() => setShareModalOpen(false)} className="share-modal">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group>
  <Form.Label style={{ color: '#fff' }}>Link da Playlist:</Form.Label>
  <Form.Control 
    type="text" 
    value={shareUrl} 
    readOnly 
    style={{
      width: '100%',
      padding: '10px 15px',
      fontSize: '16px',
      border: '2px solid #0063d7',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      color: 'black',  // Texto branco
      outline: 'none',
    }} 
  />
</Form.Group>

          <Button onClick={copyToClipboard} className="copy-link-button">Copiar Link</Button>
          <div className="share-buttons">
  <Button onClick={() => shareOnSocialMedia('whatsapp')} className="whatsapp-button">WhatsApp</Button>
  <Button onClick={() => shareOnSocialMedia('facebook')} className="facebook-button">Facebook</Button>
  <Button onClick={() => shareOnSocialMedia('twitter')} className="twitter-button">Twitter</Button>
</div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="playlist-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Adicionar à Playlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Selecionar Playlist Existente</Form.Label>
              <Form.Select
                value={selectedPlaylist}
                onChange={(e) => setSelectedPlaylist(e.target.value)}
              >
                <option value="">Selecione uma playlist</option>
                {playlists.map((playlist) => (
                  <option key={playlist.id} value={playlist.id}>
                    {playlist.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Ou Criar Nova Playlist</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome da nova playlist"
                value={newPlaylistName}
                onChange={(e) => setNewPlaylistName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Data de Término da Playlist</Form.Label>
              <Form.Control
                type="date"
                value={playlistEndDate}
                onChange={(e) => setPlaylistEndDate(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAddToPlaylist}>
            Adicionar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LouvorMinisterio;
