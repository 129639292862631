import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import styles from './AdminPanel.module.css';

// Configuração do Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDb93paSqsSU-9NKUeF8DR2qoA_xuVrRMY",
    authDomain: "cinepib-ca74f.firebaseapp.com",
    projectId: "cinepib-ca74f",
    storageBucket: "cinepib-ca74f.appspot.com",
    messagingSenderId: "882257150511",
    appId: "1:882257150511:web:f3e22139d11add178d7cda",
    measurementId: "G-SX0L31ZBZT"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Configurações do Bunny CDN
const storageZoneName = 'piblouvor';
const accessKey = '41a771cb-5350-4ba2-b19e71a584e6-484d-43cc';
const storageUrl = `https://storage.bunnycdn.com/${storageZoneName}/`;
const pullZoneUrl = 'https://vslouvorpib.b-cdn.net/';

function AdminPanel() {
    const [name, setName] = useState('');
    const [singer, setSinger] = useState('');
    const [coverUrl, setCoverUrl] = useState('');
    const [tones, setTones] = useState([]);
    const [vsFile, setVsFile] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

  const availableTones = [
    'Original', 'C', 'Cm', 'C#', 'C#m', 'D', 'Dm', 'D#', 'D#m', 'E', 'Em',
    'F', 'Fm', 'F#', 'F#m', 'G', 'Gm', 'G#', 'G#m', 'A', 'Am', 'A#', 'A#m', 'B', 'Bm'
  ];

  const uploadFileToBunny = async (file, progressCallback) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', storageUrl + encodeURIComponent(file.name), true);
    xhr.setRequestHeader('AccessKey', accessKey);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          progressCallback(percentComplete);
        }
      };
  
      return new Promise((resolve, reject) => {
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(pullZoneUrl + encodeURIComponent(file.name));
          } else {
            reject(new Error('Erro no upload: ' + xhr.statusText));
          }
        };
        xhr.onerror = () => reject(new Error('Erro de rede'));
        xhr.send(file);
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (!vsFile || !audioFile) {
        alert('Por favor, selecione um arquivo VS e um arquivo de áudio para upload.');
        return;
      }
  
      try {
        // Upload VS file
        const vsDownloadUrl = await uploadFileToBunny(vsFile, (progress) => {
          setUploadProgress(progress / 2);
        });
  
        // Upload audio file
        const audioDownloadUrl = await uploadFileToBunny(audioFile, (progress) => {
          setUploadProgress(50 + progress / 2);
        });
  
        const newVT = {
          name,
          singer,
          coverUrl,
          tones,
          downloadUrl: vsDownloadUrl,
          audioUrl: audioDownloadUrl
        };
  
        await addDoc(collection(db, 'vslouvor'), newVT);
        
        alert('VS adicionado com sucesso!');
        // Limpar o formulário
        setName('');
        setSinger('');
        setCoverUrl('');
        setTones([]);
        setVsFile(null);
        setAudioFile(null);
        setUploadProgress(0);
      } catch (error) {
        console.error('Erro ao adicionar VT:', error);
        alert('Erro ao adicionar VT: ' + error.message);
      }
    };
  

  const handleToneClick = (tone) => {
    if (tones.includes(tone)) {
      setTones(tones.filter(t => t !== tone));
    } else {
      setTones([...tones, tone]);
    }
  };

  return (
    <div className={styles.adminPanelWrapper}>
      <h2 className={styles.title}>Graça e paz!! Recomendo evitar o uso do celular para adicionar VS </h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Nome da Música</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Nome da música aqui"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="singer">Nome do Vocalista ou Versão</label>
          <input
            type="text"
            id="singer"
            value={singer}
            onChange={(e) => setSinger(e.target.value)}
            required
            placeholder="Digite o nome do cantor"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="coverUrl">Pesquise a música no Google, encontre a imagem e cole o link aqui.</label>
          <input
            type="text"
            id="coverUrl"
            value={coverUrl}
            onChange={(e) => setCoverUrl(e.target.value)}
            required
            placeholder="Cole a URL da imagem de capa"
          />
        </div>
        {coverUrl && (
          <div className={styles.coverPreview}>
            <img src={coverUrl} alt="Pré-visualização da capa" />
          </div>
        )}
        <div className={styles.formGroup}>
          <label>Tons:</label>
          <div className={styles.tonesContainer}>
            {availableTones.map((tone) => (
              <button
                key={tone}
                type="button"
                className={`${styles.toneButton} ${tones.includes(tone) ? styles.selectedTone : ''}`}
                onClick={() => handleToneClick(tone)}
              >
                {tone}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="vsFile">Arquivo VS (ZIP ou RAR):</label>
          <input
            type="file"
            id="vsFile"
            accept=".zip,.rar"
            onChange={(e) => setVsFile(e.target.files[0])}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="audioFile">Arquivo de Áudio (MP3):</label>
          <input
            type="file"
            id="audioFile"
            accept=".mp3"
            onChange={(e) => setAudioFile(e.target.files[0])}
            required
          />
        </div>
        <button type="submit" className={styles.submitButton}>Enviar VS</button>
      </form>
      {uploadProgress > 0 && (
        <div className={styles.progressContainer}>
          <progress value={uploadProgress} max="100"></progress>
          <p className={styles.progressText}>{uploadProgress.toFixed(2)}% Concluído</p>
        </div>
      )}
    </div>
  );
}

export default AdminPanel;