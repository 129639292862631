import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AdminLogin.module.css';

function AdminLogin() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'salmos33') {
      navigate('/admin-panel');
    } else {
      setError('Afastai-vos de mim, senha incorreta!');
    }
  };

  return (
    <div className={styles.adminLoginContainer}>
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        <h2 className={styles.loginTitle}>Login Administrativo</h2>
        {error && <div className={styles.errorMessage}>{error}</div>}
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Digite a senha"
          className={styles.passwordInput}
        />
        <button type="submit" className={styles.submitButton}>Entrar</button>
      </form>
    </div>
  );
}

export default AdminLogin;